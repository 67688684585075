import React, { ReactNode, useCallback, useEffect, useState } from "react"
import {
  Link,
  Trans,
  useI18next,
  useTranslation,
} from "gatsby-plugin-react-i18next"
import classNames from "classnames"
import { throttle } from "utils/helpers"
import { Logo } from "./svg"

export type MenuItem = {
  id: number
  slug: string
  title: string | ReactNode
  titleVar: string
  className?: string
}

const StickyMenu = ({
  visible = true,
  pageSlug,
}: {
  visible?: boolean
  pageSlug?: string
}) => {
  const { t } = useTranslation()
  const menuItems: MenuItem[] = [
    {
      id: 0,
      slug: pageSlug ? "/" : `/#`,
      title: (
        <Logo
          className="svg-smokeBlack mb-1.5 mr-10 lg:mr-14 xl:mr-20"
          style={{ width: "84px" }}
        />
      ),
      titleVar: "Go to home",
      className: "logo",
    },
  ]

  if (!pageSlug) {
    menuItems.push(
      {
        id: 1,
        slug: `/#${t("contact")}`,
        title: "Contact",
        titleVar: "Scroll to contact",
        className: "anchor-link ml-0 xl:ml-2 mt-8 md:mt-0",
      },
      {
        id: 2,
        slug: `/#${t("colabs")}`,
        title: "Colabs",
        titleVar: "Scroll to colabs",
        className: "anchor-link",
      },
      {
        id: 3,
        slug: `/#${t("mission")}`,
        title: "Mission",
        titleVar: "Scroll to mission",
        className: "anchor-link",
      },
      {
        id: 4,
        slug: `/#${t("services")}`,
        title: "Services",
        titleVar: "Scroll to services",
        className: "anchor-link",
      }
    )
  }

  menuItems.push({
    id: 5,
    slug: `/career`,
    title: "Career",
    titleVar: "Go to career page",
    className: "md:flex-1 md:text-right md:pr-5 mt-7 md:mt-0",
  })

  const [activeElem, setActiveElem] = useState<{
    id: number
    slug: string
    title: any
  }>(menuItems[0])
  const [isVisible, setVisible] = useState<boolean>(visible)
  const [isOpen, setOpen] = useState<boolean>(false)
  const { languages, language, originalPath } = useI18next()

  const onScroll = throttle(
    useCallback(() => {
      if (!isVisible) {
        setVisible(true)
      }
      Array.from(document.querySelectorAll("section")).forEach(section => {
        if (
          section.getBoundingClientRect().top <
            Math.floor(window.innerHeight * 0.15) &&
          section.getBoundingClientRect().bottom >
            Math.floor(window.innerHeight * 0.15) &&
          activeElem.slug !== `/#${section.id}`
        ) {
          setActiveElem(menuItems.find(i => i.slug === `/#${section.id}`))
        }
      })
    }, [isVisible, activeElem]),
    25
  )

  useEffect(() => {
    if (!pageSlug) {
      window.addEventListener("scroll", onScroll)
    }

    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  }, [onScroll])

  useEffect(() => {
    setVisible(visible)
  }, [visible])

  return (
    <>
      <div
        id="anchor-menu"
        className={classNames([
          "menu",
          { "menu--visible": isVisible },
          { "menu--open": isOpen },
        ])}
      >
        <div className="container">
          <div className="row relative">
            <div className="menu--items">
              {menuItems.map(item => (
                <Link
                  key={item.slug}
                  to={item.slug}
                  onClick={() => {
                    setOpen(false)

                    if (typeof window.dataLayer !== "undefined") {
                      window.dataLayer.push({
                        event: "Menu interaction",
                      })
                    }
                  }}
                  className={classNames([
                    item.className,
                    {
                      active: pageSlug
                        ? pageSlug === item.slug
                        : activeElem.id === item.id,
                    },
                  ])}
                >
                  <Trans>{item.title}</Trans>
                </Link>
              ))}
            </div>
            {languages
              .filter(lng => lng !== language)
              .map(lng => (
                <Link
                  key={lng}
                  to={originalPath}
                  language={lng}
                  className="language-pick"
                >
                  <span>
                    <Trans>{lng}</Trans>
                  </span>
                </Link>
              ))}
            <div
              className={classNames([
                "menu-icon",
                "md:hidden",
                "ml-4",
                { active: isOpen },
              ])}
              onClick={() => setOpen(!isOpen)}
            >
              <div>
                <span />
                <span />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default StickyMenu
