import React, { ReactNode } from 'react';
import Footer from 'components/footer';
import { Helmet, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql, useStaticQuery } from 'gatsby';

interface Props {
  path: string,
  children?: ReactNode
}

const Layout = ({ path, children }: Props) => {
  const {t} = useTranslation();
  const {seoImg, site} = useStaticQuery(graphql`
    query LayoutQuery {
      seoImg: imageSharp(sizes: {originalName: {eq: "seo-img.png"}}) {
        fixed(width: 1280, height: 720) {
          src
        }
      }
      site {
        siteMetadata {
          siteURL
        }
      }
    }
  `);

  return (
  <>
    <Helmet
      defaultTitle={t('seoTitle')}
    >
      <meta property="og:title" content={t('seoTitle')}/>
      <meta property="og:description" content={t('seoDescription')}/>
      <meta property="og:image" content={`${site.siteMetadata.siteURL}${seoImg.fixed.src}`}/>
      <meta property="og:url" content={`${site.siteMetadata.siteURL}${path}`}/>
      <meta 
        name="description" 
        content={t('seoDescription')} />
      <meta 
        name="title" 
        content={t('seoTitle')} />
      <link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href="/fonts/EuclidFlex/EuclidFlex-Bold-WebXL.woff2" />
      <link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href="/fonts/EuclidFlex/EuclidFlex-Regular-WebXL.woff2" />
      <link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href="/fonts/EuclidFlex/EuclidFlex-RegularItalic-WebXL.woff2" />
      <link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href="/fonts/EuclidFlex/EuclidFlex-Light-WebXL.woff2" />
      <link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href="/fonts/EuclidFlex/EuclidFlex-LightItalic-WebXL.woff2" />
      <link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href="/fonts/EuclidCircularB/EuclidCircularB-Bold-WebXL.woff2" />
      <link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href="/fonts/EuclidCircularB/EuclidCircularB-Light-WebXL.woff2" />
      <link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href="/fonts/EuclidCircularB/EuclidCircularB-Medium-WebXL.woff2" />
      <link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href="/fonts/EuclidCircularB/EuclidCircularB-Regular-WebXL.woff2" />
      <link rel="preload" as="font" type="font/woff2" crossOrigin="anonymous" href="/fonts/EuclidCircularB/EuclidCircularB-Semibold-WebXL.woff2" />
      <link rel="preload" as="style" href="/fonts/fonts.css" />
      <link rel="stylesheet" href="/fonts/fonts.css" />
      <meta 
        name="keywords" 
        content={[
          t('software development'),
          t('business support'),
          t('design'),
          t('project management'),
          t('digitalisation'),
          t('process development'),
        ].join(',')} />
    </Helmet>
    {children}
    <Footer/>
  </>
)};

export default Layout;
