import React from 'react';
import { Logo } from "components/svg";
import { Link, Trans, useI18next } from "gatsby-plugin-react-i18next";

const Footer = () => {
  const {languages, language, originalPath, t} = useI18next();
  
  return (
    <footer>
      <div className="container pt-36 pb-16">
        <div className="row gap-0 md:gap-x-10">
          <div className="flex flex-col justify-start col-span-5 md:col-span-4 md:col-start-5 lg:col-span-2 lg:col-start-6 md:text-center mb-0 md:mb-10">
            <Logo className="svg-dark" />
            {languages.filter(lng => lng !== language).map((lng) => (
              <div key={lng} className="text-base lowercase mt-4">
                <Link to={originalPath} language={lng} className={"text-pinkWhiteBgText"}>
                  <Trans>In {lng}</Trans>
                </Link>
              </div>
            ))}
          </div>
          <div className="flex flex-col justify-top col-span-12 mt-10 md:mt-0 md:text-right md:col-span-6">
            <h3 className="mb-4 font-extralight">
              <Trans>You find us in</Trans>
            </h3>
            <p>
              <Trans>Gothenburg, Sweden</Trans><br />
              <Trans>Berlin, Germany</Trans>
            </p>
          </div>
          <div className="flex flex-col justify-start text-left mt-6 md:mt-0 col-span-12 md:col-span-6">
            <h3 className="mb-4 font-extralight">
              <Trans>Don't be a stranger</Trans>
            </h3>
            <p>
              <Link to={`/career`} className="mb-2 text-pinkWhiteBgText">{t('Career page')}</Link><br />
              <a href="mailto:hello@melkercompany.se" className="text-pinkWhiteBgText mb-4">hello@melkercompany.se</a>
            </p>
            <div>
              <Link to={`/#${t('contact')}`} className="contact-button small">
                <Trans>Contact us</Trans>
              </Link>
            </div>
          </div>
          <div className="col-span-12 md:text-center mt-20">
            <small className="text-xs">© 2021 Melker & Company AB. <Trans>All rights reserved</Trans></small>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;