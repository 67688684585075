export { default as Logo } from "assets/svg/logo.svg";
export { default as Fei } from "assets/svg/fei-logo.svg";
export { default as Aurobay } from "assets/svg/aurobay-logo.svg";
export { default as InhouseTech } from "assets/svg/inhousetech-logo.svg";
export { default as EkFriends } from "assets/svg/ek-logo.svg";
export { default as Innovatum } from "assets/svg/innovatum-logo.svg";
export { default as Systemweaver } from "assets/svg/systemweaver-logo.svg";
export { default as Ojity } from "assets/svg/ojity-logo.svg";
export { default as ArrowElbowDownLeft } from "assets/svg/arrow-elbow-down-left.svg";
export { default as ArrowCircleRight } from "assets/svg/arrow-circle-right.svg";
export { default as ArrowFatLeft } from "assets/svg/arrow-fat-left.svg";
export { default as ArrowDown } from "assets/svg/arrow-down.svg";
export { default as ArrowRight } from "assets/svg/arrow-right.svg";
export { default as ArrowLeft } from "assets/svg/arrow-left.svg";
